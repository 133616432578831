<template>
  <div>
    <el-container>
      <CommonTree
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :isShowdig="false"
        :showCheckbox="false"
        :treeTitle="$t(`cip.plat.intf.serviceType.title.indexHeadTitle`)"
        @getTreeAdd="treeMenuAdd"
        @getTreeEdit="treeMenuEdit"
        @getTreeDelete="treeNodeDel"
        @getNodeClick="treeNodeClick"
      />
      <el-main>
        <interface-log-list ref="gridHeadLayout" :isInterface="isInterface" :logId="logId"></interface-log-list>
      </el-main>
      </el-container>
  </div>
</template>

<script>

import {remove as removeTree} from "@/api/system/intfServiceType";

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import InterfaceLogList from "@/views/system/interfaceLog/interfaceLogList";
import CommonTree from "@/views/components/com_tree/index";

import {getTree } from '@/api/system/interfaceLog'

export default {
  name: "interfaceList",
  components: {
    GridLayout,
    HeadLayout,
    InterfaceLogList,
    CommonTree
  },
  data() {
    return {
      box: false,
      logId: '',
      isInterface: false,
      treeData:[],
      defaultProps: {
        label: "name",
        value: "id",
        key: "id"
      },
      searchTitle: 'name',
      tableLoading: true,
      tableData: [],
      dataTotal: null,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    searchColumns() {
      return [
        {
          prop: "name",
          span: 4,
          type: 'input',
          placeholder: this.$t("cip.plat.intf.interface.field.name"),
        },
        {
          prop: "code",
          span: 4,
          type: 'input',
          placeholder: this.$t("cip.plat.intf.interface.field.code"),
        },
        {
          prop: "inputName",
          span: 4,
          type: 'input',
          placeholder: this.$t("cip.plat.intf.interface.field.devName"),
        },
        {
          prop: "outputName",
          span: 4,
          type: 'input',
          placeholder: this.$t("cip.plat.intf.interface.field.consumerName"),
        },
        {
          prop: "status",
          type: 'select',
          dataType: 'string',

          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          placeholder: this.$t(`cip.plat.intf.interface.field.status`)
        },
      ]
    },
    tableOptions() {
      return {
        height: 0,
        customAddBtn: true,
        column: [
          {
            label: this.$t('cip.plat.intf.interface.field.name'),
            prop: 'name',
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.intf.interface.field.code'),
            prop: 'code',
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.intf.interface.field.devName'),
            prop: 'inputName',
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.intf.interface.field.devUrl'),
            prop: 'inputUrl',
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.intf.interface.field.consumerName'),
            prop: 'outputName',
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.intf.interface.field.status'),
            prop: 'status',
            align: "center",
            type: 'select',
            dataType: 'string',
            span: 8,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",

          }
        ],
      }
    },
    gridRowBtn() {
      let result = [];
      //新增按钮
      if (this.permission.interfaceLog_view) {
        result.push({
          label: this.$t('cip.cmn.btn.viewBtn'),
          emit: "row-view",
          type: "button",
          icon: ""
        });
      }
      return result;
    },
    gridHeadBtn() {
      return []
    },
  },
  methods: {
    // 点击左侧树节点
    treeNodeClick(node) {
      this.node = node;
      const {id} = node;
      this.logId = id
      this.isInterface = node.isInterface
    },
    // 删除左侧树节点
    treeNodeDel(data, done) {
      if (data.hasChildren) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.hasChildrenWarning'))
        return
      }

      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      }).then(() => {
        removeTree(data.id).then(res => {
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
          this.initTree()
          done()
        })
      }).catch(() => {
      });
    },
    // 左侧树编辑
    treeMenuEdit(data) {
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
      this.form = data;
    },
    // 左侧树新增
    treeMenuAdd() {
      this.form = {};
      this.form.parentId = this.node.id;
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
    },
    headRemove() {

    },
    headAddTabs() {
      this.$router.push({
        path: `/interface/interfaceListEdit`,
        query: {
          type: 'add',
          pageType: 'add',
        }
      })
    },
    rowView(row) {
      this.logId = row.id
      this.box = true
    },
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getList(
        (page.currentPage - 1) * page.pageSize,
        page.pageSize,
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.totalCount;
        this.tableData = data.list;
        this.tableLoading = false;
      });
    }
  },
  mounted() {
    // this.onLoad(this.page, {});
    getTree().then(res => {
      this.treeData = res.data.data
      let obj = {name:'全部分类',id:''}
      if(this.treeData.length > 0) this.treeData.unshift(obj)
    })
  }
};
</script>
<style>
</style>
