var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            attrs: {
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              isShowdig: false,
              showCheckbox: false,
              treeTitle: _vm.$t(
                `cip.plat.intf.serviceType.title.indexHeadTitle`
              ),
            },
            on: {
              getTreeAdd: _vm.treeMenuAdd,
              getTreeEdit: _vm.treeMenuEdit,
              getTreeDelete: _vm.treeNodeDel,
              getNodeClick: _vm.treeNodeClick,
            },
          }),
          _c(
            "el-main",
            [
              _c("interface-log-list", {
                ref: "gridHeadLayout",
                attrs: { isInterface: _vm.isInterface, logId: _vm.logId },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }