<template>
  <div>
    <el-row>
      <el-col>
        <head-layout
          :head-title="$t(`cip.plat.intf.interface.title.logListHeadTitle`)"
        ></head-layout>
      </el-col>
    </el-row>
    <grid-head-layout
      ref="gridHeadLayout"
      :grid-head-btn="gridHeadBtn"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @grid-row-detail-click="rowDetail"
      @row-view="rowView"
    >
    </grid-layout>
    <CommonDialog v-if="detail" :showBtn="false" class="dialog" width="50%" :dialogTitle="$t(`cip.plat.intf.interface.field.params`)" @cancel="detail = false">
      <el-form  label-width="120px">
        <el-form-item :label="$t(`cip.plat.intf.interface.field.inputParams`)">
          <el-input
            type="textarea"
            v-model="detailFormData.inputParams"
            show-word-limit
            :rows="10"
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="$t(`cip.plat.intf.interface.field.outputParams`)">
          <el-input
            type="textarea"
            v-model="detailFormData.outputParams"
            show-word-limit
            :rows="10"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </CommonDialog>
  </div>
</template>

<script>

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

import {
  getList
} from '@/api/system/interfaceLog'
import CommonDialog from "@/components/CommonDialog";
export default {
  props: {
    logId: '',
    isInterface:''
  },
  name: "interfaceList",
  components: {
    GridLayout,
    HeadLayout,
    CommonDialog
  },
  data() {
    return {
      detailFormData: {},
      tableLoading: true,
      detail: false,
      tableData: [],
      dataTotal: null,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    searchColumns() {
      return [
        {
          prop: "inputStatus",
          type: 'select',
          dataType: 'string',
          span: 4,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=isSucess",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          placeholder: this.$t(`cip.plat.intf.interface.field.inputStatus`)
        },
        {
          prop: "outputStatus",
          type: 'select',
          dataType: 'string',
          span: 4,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=isSucess",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          placeholder: this.$t(`cip.plat.intf.interface.field.outputStatus`)
        },
      ]
    },
    tableOptions() {
      return {
        height: 0,
        customAddBtn: true,
        column: [
          {
            label: this.$t('cip.plat.intf.interface.field.status'),
            prop: 'status',
            align: "center",
            type: 'select',
            dataType: 'string',
            span: 8,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            width: 120,
            overHidden: true,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",

          },
          {
            label: this.$t('cip.plat.intf.interface.field.inputStatus'),
            prop: 'inputStatus',
            align: "center",
            type: 'select',
            dataType: 'string',
            span: 8,
            width: 120,
            overHidden: true,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=isSucess",
          },
          {
            label: this.$t('cip.plat.intf.interface.field.outputStatus'),
            prop: 'outputStatus',
            align: "center",
            type: 'select',
            dataType: 'string',
            width: 120,
            overHidden: true,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=isSucess",
          },
          {
            label: this.$t('cip.plat.intf.interface.field.responseTimeExt'),
            prop: 'responseTimeExt',
            width: 200,
            align: "center",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.intf.interface.field.inputParams'),
            prop: 'inputParams',
            width: 200,
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.intf.interface.field.outputParams'),
            prop: 'outputParams',
            width: 200,
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.intf.interface.field.failedReason'),
            prop: 'failedReason',
            align: "left",
            overHidden: true,
          }

        ],
      }
    },
    gridRowBtn() {
      let result = [];
      //新增按钮
      if (this.permission.interfaceLog_view) {
        result.push({
          label: this.$t('cip.cmn.btn.viewBtn'),
          emit: "row-view",
          type: "button",
          icon: ""
        });
      }
      return result;
    },
    gridHeadBtn() {
      return []
    },
  },
  mounted() {
    this.onLoad(this.page);
  },
  watch:{
    logId(newValue,oldValue){
        this.onLoad(this.page)
    }
  },
  methods: {
    headRemove(){

    },
    headAddTabs() {
      this.$router.push({
        path: `/interface/interfaceListEdit`,
        query: {
          type: 'add',
          pageType: 'add',
        }
      })
    },
    rowEdit(row) {
      this.$router.push({
        path: `/interface/interfaceListEdit`,
        query: {
          id: row.id,
          type: 'edit',
          pageType: 'edit',
        }
      })
    },
    rowView(row) {
      this.detailFormData = row
      this.detail = true
    },
    rowDetail(row) {
      this.$router.push({
        path: `/interface/interfaceListEdit`,
        query: {
          id: row.id,
          type: 'view',
          pageType: 'detail',
        }
      })
    },
    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      params={
        intfId:this.logId,
        isInterface: this.isInterface
      }
      getList(
        (page.currentPage-1)*page.pageSize,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.totalCount;
        this.tableData = data.list;
        this.tableLoading = false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.dialog{
  height: 80%;
}


</style>
