var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": _vm.$t(
                    `cip.plat.intf.interface.title.logListHeadTitle`
                  ),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: {
          "grid-head-btn": _vm.gridHeadBtn,
          "search-columns": _vm.searchColumns,
        },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "grid-row-btn": _vm.gridRowBtn,
          "table-options": _vm.tableOptions,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "grid-row-detail-click": _vm.rowDetail,
          "row-view": _vm.rowView,
        },
      }),
      _vm.detail
        ? _c(
            "CommonDialog",
            {
              staticClass: "dialog",
              attrs: {
                showBtn: false,
                width: "50%",
                dialogTitle: _vm.$t(`cip.plat.intf.interface.field.params`),
              },
              on: {
                cancel: function ($event) {
                  _vm.detail = false
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "120px" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t(
                          `cip.plat.intf.interface.field.inputParams`
                        ),
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          "show-word-limit": "",
                          rows: 10,
                        },
                        model: {
                          value: _vm.detailFormData.inputParams,
                          callback: function ($$v) {
                            _vm.$set(_vm.detailFormData, "inputParams", $$v)
                          },
                          expression: "detailFormData.inputParams",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t(
                          `cip.plat.intf.interface.field.outputParams`
                        ),
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          "show-word-limit": "",
                          rows: 10,
                        },
                        model: {
                          value: _vm.detailFormData.outputParams,
                          callback: function ($$v) {
                            _vm.$set(_vm.detailFormData, "outputParams", $$v)
                          },
                          expression: "detailFormData.outputParams",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }